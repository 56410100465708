<template>
  <a-modal
    v-model:visible="visible"
    :confirmLoading="btnLoading"
    :title="$t('account.update_timezone')"
    :maskClosable="false"
    @ok="handleSubmit"
  >
    <a-spin :spinning="loading">
      <a-row type="flex" justify="center">
        <a-col>
          <a-select
            :allowClear="true"
            v-model:value="timezone"
            show-search
            optionFilterProp="search-key"
            :placeholder="$t('account.choose_timezone')"
            style="width: 300px"
          >
            <a-select-option
              :value="item.zoneName"
              v-for="item in timezoneList"
              :search-key="
                item.zoneName +
                item.countryCnName +
                item.countryEnName +
                item.countryCode +
                item.gmtOffset
              "
              :title="`${item.zoneName}(${getLanguageName({
                cnName: item.countryCnName,
                enName: item.countryEnName,
              })})+${item.gmtOffset}`"
              :key="item.id"
            >
              {{ item.zoneName }}({{
                getLanguageName({
                  cnName: item.countryCnName,
                  enName: item.countryEnName,
                })
              }}+{{ item.gmtOffset }})
            </a-select-option>
          </a-select>
        </a-col>
      </a-row>
    </a-spin>
  </a-modal>
</template>

<script>
import { defineComponent, nextTick, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n/index";
import { Modal, Select, Row, Col, Spin, message } from "ant-design-vue";
import { getTimeZoneList, updateTimezone } from "@/api/modules/account";
import { getName } from "@/utils/general.js";

export default defineComponent({
  components: {
    AModal: Modal,
    ASpin: Spin,
    ARow: Row,
    ACol: Col,
    ASelect: Select,
    ASelectOption: Select.Option,
  },
  setup() {
    const { getters, dispatch } = useStore();
    const vueI18n = useI18n({ useScope: "global" });

    const state = reactive({
      visible: false,
      loading: true,
      btnLoading: false,
      timezone: null,
      timezoneList: [],
    });

    const handleSubmit = async () => {
      if (!state.timezone) {
        message.error(vueI18n.t('common.p0_is_required', [vueI18n.t('account.timezone')]))
        return
      }
      try {
        state.btnLoading = true;
        await updateTimezone({ timezone: state.timezone })
        await dispatch('user/reloadUserInfo');
        message.success(vueI18n.t('common.succeed'));
        state.visible = false
      } catch (error) {
        
      } finally {
        state.btnLoading = false;
      }
    };

    const getLanguageName = (item) => {
      return getName(item, getters.language);
    };

    const init = (timezone) => {
      state.timezone = timezone
      state.visible = true;
      state.loading = true;
      nextTick(() => {
        getTimeZoneList()
          .then((res) => {
            let { result } = res;
            if (result) {
              state.timezoneList = result;
            }
          })
          .finally(() => {
            state.loading = false;
          });
      });
    };

    return {
      ...toRefs(state),
      handleSubmit,
      getLanguageName,
      init,
    };
  },
});
</script>
