<template>
  <Content>
    <template #contentTitle>
      {{ $t("menu.account_info") }}
    </template>
    <template #contentBody>
      <a-row type="flex">
        <a-col :span="24">
          <a-space direction="vertical" :size="30" style="width: 100%">
            <a-card
              :title="$t('account.essential_information')"
              :bordered="false"
              size="small"
            >
              <a-form
                :model="formState"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
                :colon="false"
              >
                <a-form-item :label="$t('account.corporate_name')">
                  <span>{{ formState.shopName }}</span>
                </a-form-item>
                 <a-form-item :label="$t('account.company_id')">
                  <span>{{ formState.shopNo }}</span>
                </a-form-item>
                 <a-form-item :label="$t('account.company_characteristic')">
                  <span>{{ formState.shopMark }}</span>
                </a-form-item>
                <a-form-item :label="$t('account.timezone')">
                  <a-space>
                    <span>{{ formState.timezone }}</span>
                    <a-button type="link" @click="handleShowEditTimezone(formState.timezone)">{{
                      $t("account.modify")
                    }}</a-button>
                  </a-space>
                </a-form-item>
                <a-form-item :label="$t('account.registration_number')">
                  <span>{{ formState.businessLicenseCode }}</span>
                </a-form-item>
                <a-form-item :label="$t('account.account_manager')">
                  <span>{{ formState.managerRealName }}</span>
                </a-form-item>
                <a-form-item :label="$t('account.mobile_phone')">
                  <span>{{ formState.managerPhoneNumber }}</span>
                </a-form-item>
                <a-form-item :label="$t('account.secure_mailbox')">
                  <a-space v-if="formState.mail">
                    <span>{{ formState.mail }}</span>
                    <!-- <a-button type="link" @click="handleUpdateEmail">{{
                      $t("account.modify")
                    }}</a-button> -->
                  </a-space>
                  <!-- <a-button v-else @click="handleSetEmail">{{
                    $t("common.add")
                  }}</a-button> -->
                </a-form-item>
              </a-form>
            </a-card>
            <a-card :title="$t('account.account_security')" :bordered="false" size="small">
              <a-form
                :model="formState"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
                :colon="false"
              >
                <a-form-item :label="$t('common.password')">
                  <a-space>
                    <span>************</span>
                    <a-button type="link" @click="handleShowEditPwdModal">{{
                      $t("account.change_password")
                    }}</a-button>
                  </a-space>
                </a-form-item>
              </a-form>
            </a-card>
          </a-space>
        </a-col>
      </a-row>

      <a-modal
        v-model:visible="modalData.visible"
        :confirmLoading="modalData.loading"
        :title="$t('account.change_password')"
        :maskClosable="false"
        @ok="handleSubmit"
      >
        <a-form
          ref="formRef"
          :model="modalData.formData"
          :rules="formRules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          :colon="false"
        >
          <a-form-item
            has-feedback
            :label="$t('account.original_password')"
            name="oldPassWord"
          >
            <a-input-password v-model:value="modalData.formData.oldPassWord" />
          </a-form-item>
          <a-form-item
            has-feedback
            :label="$t('account.new_password')"
            name="newPassWord"
          >
            <a-input-password v-model:value="modalData.formData.newPassWord" />
          </a-form-item>
          <a-form-item
            has-feedback
            :label="$t('account.enter_the_new_password_again')"
            name="reNewPassWord"
          >
            <a-input-password v-model:value="modalData.formData.reNewPassWord" />
          </a-form-item>
        </a-form>
      </a-modal>

      <EditTimezoneModal ref="timezoneModalRef" />
    </template>
  </Content>
</template>
<script>
import {
  Row,
  Col,
  Space,
  Card,
  Form,
  Button,
  Modal,
  Input,
  message,
} from "ant-design-vue";
import CPager from "../components/CPager.vue";
import Content from "../components/Content.vue";
import EditTimezoneModal from './components/EditTimezoneModal.vue';
import {
  watch,
  defineComponent,
  nextTick,
  onMounted,
  reactive,
  ref,
  toRefs,
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n/index";
import { updatePwd } from "@/api/modules/account/index";

export default defineComponent({
  name: "account_info",
  components: {
    ARow: Row,
    ACol: Col,
    ASpace: Space,
    ACard: Card,
    AForm: Form,
    AFormItem: Form.Item,
    AButton: Button,
    AModal: Modal,
    AInput: Input,
    AInputPassword: Input.Password,
    CPager,
    Content,
    EditTimezoneModal,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });
    const { dispatch, getters } = useStore();
    const router = useRouter();
    const formRef = ref(null);
    const timezoneModalRef = ref(null);

    const state = reactive({
      formState: {
        shopNo:null,
        shopName: "",
        timezone: "",
        shopMark:"",
        businessLicenseCode: "",
        managerRealName: "",
        managerPhoneNumber: "",
        mail: "",
      },
      labelCol: { style: { width: "100px" } },
      wrapperCol: { span: 14, offset: 1 },
      modalData: {
        visible: false,
        loading: false,
        formData: {
          oldPassWord: "",
          newPassWord: "",
          reNewPassWord: "",
        },
      },
    });

    const validatePass = (rule, value) => {
      if (value === "") {
        return Promise.reject(vueI18n.t('common.p0_is_required', [vueI18n.t('account.new_password')]));
      } else {
        if (state.modalData.formData.reNewPassWord !== "") {
          formRef.value.validateFields("reNewPassWord");
        }
        return Promise.resolve();
      }
    };

    const validateRePass = (rule, value) => {
      if (value === "") {
        return Promise.reject(vueI18n.t('common.p0_is_required', [vueI18n.t('account.enter_the_new_password_again')]));
      } else if (value !== state.modalData.formData.newPassWord) {
        return Promise.reject(
          vueI18n.t("account.the_passwords_entered_twice_do_not_match")
        );
      } else {
        return Promise.resolve();
      }
    };

    const formRules = {
      oldPassWord: [
        {
          required: true,
          message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('account.original_password')])
        },
      ],
      newPassWord: [
        { required: true, validator: validatePass, trigger: "change" },
      ],
      reNewPassWord: [{ validator: validateRePass, trigger: "change" }],
    };

    // 修改邮箱
    const handleUpdateEmail = () => {};

    // 设置邮箱
    const handleSetEmail = () => {};

    const handleShowEditPwdModal = () => {
      state.modalData.visible = true;
      nextTick(() => {
        formRef.value.resetFields()
      })
    };

    // 修改时区
    const handleShowEditTimezone = (timezone) => {
      timezoneModalRef.value.init(timezone);
    }

    // 修改密码
    const handleSubmit = () => {
      formRef.value
        .validate()
        .then((res) => {
          const { reNewPassWord, ...data } = res;
          state.modalData.loading = true;
          updatePwd(data)
            .then(() => {
              message.success(
                vueI18n.t("account.password_modified_successfully")
              );
              formRef.value.resetFields();
              state.modalData.visible = false;
              nextTick(() => {
                logout();
              });
            })
            .finally(() => {
              state.modalData.loading = false;
            });
        })
        .catch(() => {});
    };

    const logout = async () => {
      await dispatch("user/logout");
      router.push("/login");
    };

    const init = () => {
      let userInfo = getters.userInfo;
      if (userInfo) {
        for (const key in state.formState) {
          if (Object.hasOwnProperty.call(userInfo, key)) {
            state.formState[key] = userInfo[key];
          }
        }
      }
    }

    watch(() => getters.userInfo, init)

    onMounted(init);

    return {
      ...toRefs(state),
      timezoneModalRef,
      formRef,
      formRules,
      handleShowEditPwdModal,
      handleShowEditTimezone,
      handleSubmit,
      handleUpdateEmail,
      handleSetEmail,
    };
  },
});
</script>
